import { useState } from "react";
import "./about.scss";

export default function About() {

  return (
    <div className="about" id="about">
      <h1>About Me !</h1>
      <div className="aboutData">
        <div className="text">
          Hello !,
          <br />
          I am Vasanth Kumar, a passionate Computer Science graduate driven by the potential of technology to solve complex
          problems. My expertise spans Full Stack Web Development, with a knack for applying mathematical concepts to
          practical challenges.
          <br />
          <br />
          I hold a Bachelor's degree from Sri Sairam Engineering College (2017 - 2021) and currently serve as an SDE-2 at
          Amazon India, where I'm actively contributing to the software landscape. This role follows my promotion from
          SDE-1 to SDE-2 at Amazon India in December 2023, reflecting my dedication and contributions to the team.
          For further insights, my resume and social media profiles are at your disposal.
          I'm excited about the possibilities ahead and eager to connect for potential collaborations.!
          <br />
          Click here for <a className="resumeLink" href="https://drive.google.com/file/d/189_hGB2os4XHhYg8PUdvNdJdpMErq5LF/view?usp=sharing" target="_blank" rel="noreferrer">resume</a>
          <br />

          <div className="socialIcons">
            <a href="https://github.com/VasanthKumar14" target="_blank" rel="noreferrer">
              <img src="assets/github.svg" className="icon" alt="linkedIn" />
            </a>
            <a href="https://www.instagram.com/vasanth_14/" target="_blank" rel="noreferrer">
              <img src="assets/instagram.svg" className="icon" alt="linkedIn" />
            </a>
            <a href="https://www.linkedin.com/in/vasanth-kumar-967810169/" target="_blank" rel="noreferrer">
              <img src="assets/linkedin.svg" className="icon" alt="linkedIn" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100015949329736" target="_blank" rel="noreferrer">
              <img src="assets/facebook.svg" className="icon" alt="linkedIn" />
            </a>

          </div>


        </div>

        <div className="image">
          <figure>
            <img className="vkImage" src="assets/vk2.jpg" alt="VKphoto" />
          </figure>
        </div>

      </div>

    </div>
  );
}
