import "./menu.scss";

export default function Menu({ menuOpen, setMenuOpen }) {
  return (
    <div className={"menu "+(menuOpen && "active")}>
      <ul>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#intro">Home</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#about">About</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#portfolio">Projects</a>
        </li>
        <li onClick={()=>setMenuOpen(false)}>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="resume" onClick={()=>setMenuOpen(false)}>
          <a href="https://drive.google.com/file/d/189_hGB2os4XHhYg8PUdvNdJdpMErq5LF/view?usp=sharing" target="_blank" rel="noreferrer">Resume</a>
      </div>

      
    </div>
  );
}
