import { useState } from "react";
import "./contact.scss";

export default function Contact() {
  const [message, setMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target);

    let details = {
      name: e.target.name.value,
      email: e.target.email.value,
      message: e.target.message.value,
    };

    console.log(details)


    try{

      let response = await fetch("/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });

    
    let result = await response.json();
    alert(result.status);

    }
    catch{
      console.log("Email error");

    }
    setMessage(true);
    e.target.reset();
    
    
  };
  return (
    <div className="contact" id="contact">
      <div className="left">
        <img src="assets/shake.svg" alt="" />
      </div>
      <div className="right">
        <h2>Contact.</h2>
        <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Name" name="name" />
          <input type="text" placeholder="Email" name="email"/>
          <textarea placeholder="Message" name="message" ></textarea>
          <button type="submit">Send</button>
          {message && <span>Thanks, I'll reply ASAP :)</span>}
        </form>
      </div>
    </div>
  );
}
