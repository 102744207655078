export const featuredPortfolio = [
  {
    id: 1,
    title: "Disease Detection in Gastro-Intestinal using Transfer Learning",
    img:
      "assets/gtract.png",
    link: "https://www.kaggle.com/vasanthkumar14/final-year-project-gastrointestinal"
  },
  {
    id: 2,
    title: "Angular - Gold Loan Application",
    img:
      "assets/gold_loan.jpg",
    link: "https://github.com/VasanthKumar14/Virtusa-Angular-frontend"
  },
  // {
  //   id: 3,
  //   title: "Java Spring - Gold Loan Application",
  //   img:
  //     "assets/gold_loan_java.jpg",
  //   link:"https://github.com/VasanthKumar14/Virtusa-spring-backend"
  // },
  {
    id: 4,
    title: "Prevail - Flutter Application",
    img:
      "assets/prevail.jpg",
    link: "https://github.com/VasanthKumar14/PREVAIL-APP"
  },
  {
    id: 5,
    title: "Plant Disease Diagnose",
    img:
      "assets/plant.png",
    link: "https://github.com/VasanthKumar14/MINI-PROJECT-3rd-Year-"
  },
  // {
  //   id: 6,
  //   title: "Banking App",
  //   img:
  //     "https://cdn.dribbble.com/users/3307260/screenshots/15468444/media/447b4501f7a145b05e11c70199a9c417.jpg?compress=1&resize=1200x900",
  // },
];

export const webPortfolio = [
  {
    id: 1,
    title: "Web Social Media App",
    img: "https://cdn.dribbble.com/users/5031392/screenshots/15467520/media/c36b3b15b25b1e190d081abdbbf947cf.png?compress=1&resize=1200x900",
  },
  {
    id: 2,
    title: "Web Rampa UI Design",
    img: "https://cdn.dribbble.com/users/3967258/screenshots/15463803/media/4fddb9a2caf3b3bd634060f706a91e73.png?compress=1&resize=1200x900",
  },
  {
    id: 3,
    title: "Web E-commerce Design",
    img: "https://cdn.dribbble.com/users/3637068/screenshots/15467010/media/69a3279182b75365013fe285733d1122.png?compress=1&resize=1200x900",
  },
  {
    id: 4,
    title: "Web Relax App",
    img: "https://cdn.dribbble.com/users/2264844/screenshots/15463998/media/d85e92a332c41094f68e1f716884f7ce.jpg?compress=1&resize=1200x900",
  },
  {
    id: 5,
    title: "Web Design",
    img: "https://cdn.dribbble.com/users/387658/screenshots/15458608/media/e92ec1dd33dd1170ae3dc9c5272f2693.jpg?compress=1&resize=1200x900",
  },
  {
    id: 6,
    title: "Web Banking App",
    img: "https://cdn.dribbble.com/users/6384483/screenshots/15468426/media/ce9479fa1f8dba3a4a49840d76e55e31.png?compress=1&resize=1200x900",
  },
];

export const mobilePortfolio = [
  {
    id: 1,
    title: "Mobile Social Media App",
    img:
      "https://cdn.dribbble.com/users/2597268/screenshots/15468893/media/44313553d73ba41580f1df69749bba28.jpg?compress=1&resize=1200x900",
  },
  {
    id: 2,
    title: "Mobile Rampa UI Design",
    img:
      "https://cdn.dribbble.com/users/5920881/screenshots/15463142/media/b5a460223798bd85d835710806e2f3dd.png?compress=1&resize=1200x900",
  },
  {
    id: 3,
    title: "Mobile E-commerce Design",
    img:
      "https://cdn.dribbble.com/users/1998175/screenshots/15459384/media/48ac2b43ebe81ba0866afea1383cc939.png?compress=1&resize=1200x900",
  },
  {
    id: 4,
    title: "Mobile Relax App",
    img:
      "https://cdn.dribbble.com/users/2083704/screenshots/15468619/media/cd958306c7a772449e1ac23bd65ce506.png?compress=1&resize=1200x900",
  },
  {
    id: 5,
    title: "Mobile Hero Design",
    img:
      "https://cdn.dribbble.com/users/26642/screenshots/15462545/media/1a202ef5c8338b6f78634e6edc1ba350.png?compress=1&resize=1200x900",
  },
  {
    id: 6,
    title: "Mobile Banking App",
    img:
      "https://cdn.dribbble.com/users/1387827/screenshots/15466426/media/deb2dca6762cd3610321c98bfccb0b72.png?compress=1&resize=1200x900",
  },
];


